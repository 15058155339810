import React, {Component} from 'react';
import { 
	withStyles, 
	Box, 
	Grid, 
	WithStyles, 
	Theme, 
	createStyles, 
	Typography,
	TextField,
	Button,
	InputAdornment,
	CircularProgress,
	Dialog,
} from '@material-ui/core';
import MobileMock from './MobileMock';
import { Autocomplete } from '@material-ui/lab';
import dialCodes from "../../config/dialCodes";
import axios, { AxiosError, AxiosResponse } from 'axios';
 

import { URL } from '../../config/config';
import LinkDialogContent from './LinkDialogContent';
import { PlayCircleOutline } from '@material-ui/icons';
import EPicker from '../../commons/EPicker/EPicker';
import { IEmojiData } from 'emoji-picker-react';

let timer: number;
const timeoutVal = 300;

class LinkGenerator extends Component<Props, cState> {
	static defaultProps = {
		name: ""
	}
	private textarea: React.RefObject<HTMLTextAreaElement> = React.createRef();
	state = {
		country: "India",
		countryCode: "+91",
		name: this.props.name,
		msg: "",
		previewMsg: true,
		phoneNumber: "",
		isSubmitting: false,
		errmsg: "",
		linkid: ""
	}
	async componentDidMount(): Promise<void> {
		// const clientId = ""
		const typer: HTMLElement | null = document.getElementById('typer');
		if (typer) {
			typer.addEventListener('keypress', this.handleKeyPress);
			// triggers a check to see if the user is actually done typing
			typer.addEventListener('keyup', this.handleKeyUp);
		}
	}
	handleKeyUp = (e: KeyboardEvent) => {
		window.clearTimeout(timer);
		timer = window.setTimeout(() => {
			this.setState({ previewMsg: false })
		}, timeoutVal);
	}
	handleKeyPress = (e: KeyboardEvent) => {
		window.clearTimeout(timer);
		this.setState({ previewMsg: true })
	}
  handleAutoComplete = (field: string, value: string, reason: string) => {
    if(reason == 'select-option') {
      this.setState({ country: value, countryCode: this.getDialCode(value) })
    }
  }
  getDialCode = (value: string) => {
    const result = dialCodes.find( e => e.name == value);
		if (result) {
			return result.dialCode;
		} else {
			return "";
		}
  }
	handleCustomMsg = (e: React.ChangeEvent<HTMLInputElement> ) => {
		this.setState({msg: e.target.value})
	}
	handlePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({phoneNumber: e.target.value.trim()})
	}
	generateLink = async (): Promise<void> => {
		try {
			const { phoneNumber, msg, countryCode, country } = this.state;
			const dialCode = dialCodes.find( e => e.name == country );
			const isPhoneInvalid = this.formValidation();
			// if(isPhoneInvalid) {
			// 	throw { errmsg: isPhoneInvalid };
			// }
			if(!dialCode) {
				throw { message: "Dial code doesn't exist", type: "ERR104" }				
			}
			this.setState({ isSubmitting: !this.state.isSubmitting });
			const data = {
				// As per as backend validator
				entityType: "LINK",
				linkId: "",
				phoneNumber: countryCode.slice(1) + phoneNumber,
				prefilledMsg: msg,
				clientId: "",
				assistantId: "",
				whatsappBtnSettings: null
			}
			type createResp = {
				linkId: string
			}
			
			const res = await axios.post<createResp>(URL + '/create', data);
			// console.log(res);
			this.setState({ 
				linkid: res.data.linkId, 
				isSubmitting: false,
				errmsg: "" 
			});
		} catch(err) {
			console.log(err)
			type errResp = {
				message: string,
				type: string
			};
			// Handle axios error
			if (err && err.response) {
				const axiosError = err as AxiosError<errResp>;
				this.setState({
					isSubmitting: false,
					errmsg: 
						axiosError.response?.data.message || 
							"Somethig went wrong, Try again !" 
				});
				return 
			}
			// Handle direct in-function error 
			if (err && err.message) {
				this.setState({
					errmsg: err.message,
					isSubmitting: false
				});
				return;
			}
			// Fallback to generic message
			this.setState({
				errmsg: "Something went wrong, Try again !",
				isSubmitting: false
			});
		}
	}
	formValidation = (): string => {
		const { phoneNumber } = this.state;
		if(!phoneNumber) {
			return "Phone number can't be empty";
		}
		if(isNaN(Number(phoneNumber))) {
			return "Only digits are allowed in phone number"
		}
		return ""
	}
	closeDialog = () => {
		this.setState({ linkid: "", phoneNumber: "", msg: "" });
	}
	setEmoji = (event: React.MouseEvent<Element, MouseEvent>, data: IEmojiData, fieldName: string) => {
		const ta = this.textarea;
		if (this.textarea.current !== null ) {
			// console.log(ta.current?.selectionEnd, ta.current?.selectionStart);
			const cursorPos = ta.current?.selectionEnd || this.state.msg.length;
			const selectionStart = ta.current?.selectionStart || this.state.msg.length;
			const start = this.state.msg.substring(0, selectionStart);
			const end = this.state.msg.substring(selectionStart);
			const text = start + data.emoji + end;
			this.setState({ msg: text }, () => {
				// ta.current?.focus();
				if (ta.current) {
					ta.current.selectionEnd = cursorPos + 2;
					ta.current.selectionStart = cursorPos + 2;
				}
				// console.log("After update", ta.current?.selectionEnd, ta.current?.selectionStart);
			});

		} else {
			this.setState({ msg: this.state.msg + data.emoji});
		}
	}
	render(): React.ReactElement {
		const { classes } = this.props
		return (
			<div className={classes.root}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Box mx={{xs: 2, md: 0}} py={{xs:2, md: 4}} px={{xs: 2, md:4}} className={classes.guideContainer}>
							<Typography variant="h4" color="primary" paragraph style={{fontWeight: "bold"}}>
								Create WhatsApp Link
							</Typography>
							<Typography variant="body1" color="textSecondary">
								Enter the Whatsapp Mobile Number and Pre-filled text and generate your Whatsapp Link Instantly 
							</Typography>
							{/* <Box pt={2}>
								<Box
									color="primary"
									className={
										"wistia_embed wistia_async_" +
										"cqy3g0nwj9" +
										" popover=true popoverContent=link wistia_embed_initialize playerColor=04454D"
									}
								>
									<Button startIcon={<PlayCircleOutline />} color="primary">
										Watch Tutorial
									</Button>
								</Box>
							</Box> */}
						</Box>						
					</Grid>
					<Grid item xs={12} md>
						<Box pl={{xs: 2, md: 0}} pr={{ xs:2, md: 4}} py={{xs:2, md: 4}}>
							<Typography variant="body1" gutterBottom>
								WhatsApp Phone Number
							</Typography>
							<Typography variant="body2" color="textSecondary" gutterBottom>
								Select your country code & Type your WhatsApp phone number
							</Typography>
							<Grid container>
								<Grid item>
									<Autocomplete
										disableClearable
										value={this.state.country}
										options={dialCodes.map((option) => option.name)}
										onChange={(e, value, reason) => this.handleAutoComplete("country", value, reason)}
										renderInput={(params) => (
											<TextField
												{...params}
												className={classes.textField}
												InputProps={{ ...params.InputProps }}
												placeholder="Select country"
												name="Dial code"
											/>
										)}
									/> 
								</Grid>
								<Grid item xs>
									<TextField
										fullWidth
										placeholder="Your phone number goes in here"
										value={this.state.phoneNumber}
										onChange={this.handlePhoneNumber}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<Typography variant="body1">
														{this.state.countryCode}
													</Typography>
												</InputAdornment>
											),
										}}
									/>
								</Grid>
							</Grid>
							<Box py={2} />
							<Typography variant="body1" gutterBottom>
								Custom Message 
								<Box display="inline-block" px={1}>
									<EPicker setEmoji={this.setEmoji} />
								</Box>
							</Typography>
							<Typography variant="body2" color="textSecondary" gutterBottom>
								Type your custom message with emojis & WhatsApp text formatting
							</Typography>
							<TextField
								multiline
								rows={4}
								fullWidth
								placeholder="Add your customer message that user will send to you"
								id="typer"
								onChange={this.handleCustomMsg}
								value={this.state.msg}
								inputRef={this.textarea}
							/>
							<Box py={2}/>
							{
								this.state.errmsg ?
									<Typography variant="body2" color="error" gutterBottom>
											{this.state.errmsg}
									</Typography>
								: ""
							}

							<Button 
								variant={this.state.isSubmitting ? "outlined" : "contained"}
								color="primary"
								onClick={this.generateLink}
								disabled={this.state.isSubmitting}
								style={{color: "white"}}
							>
								{
									this.state.isSubmitting ?
										<CircularProgress size={20} />
									: "Generate Link"
								}
							</Button>
						</Box>
					</Grid>
					<Grid item xs={12} md={5}>
						<Box  py={4} display="flex" alignItems="center" flexDirection="column">
							<MobileMock 
								previewMsg={this.state.previewMsg} 
								msg={this.state.msg} 
								phoneNumber={this.state.phoneNumber} 
								countryCode={this.state.countryCode}
							/>
							<Typography variant="body1" color="textSecondary" component="span">
								Message Preview 
							</Typography>
						</Box>
					</Grid>
				</Grid>
				<Dialog
          open={!!this.state.linkid}
          onClose={this.closeDialog}
          aria-labelledby="form-dialog-title"
          maxWidth="xs"
          fullWidth={true}
          disableBackdropClick
        >
					<LinkDialogContent linkid={this.state.linkid} closeDialog={this.closeDialog}/>
				</Dialog>
			</div>
		)
	}
}

interface Props extends WithStyles<typeof styles> {
	name: string,
	// number: string
}

interface cState {
	country: string,
	countryCode: string,
	name: string,
	msg: string,
	previewMsg: boolean,
	phoneNumber: string,
	isSubmitting: boolean,
	errmsg: string,
	linkid: string
}

const styles = () => createStyles({
	"root": {
		width: "100%",
		backgroundColor: "white",
		boxSizing: "border-box",
		borderRadius: 8,
		// padding: "25px 0px",
    // border: "1px solid lightgrey",
    minHeight: 100
	},
	textField: {
		width: 120,
		marginRight: 10
	},
	guideContainer: {
    background: "white",
    borderRadius: "8px",
		// border: "1px solid lightgrey"
		backgroundColor: "rgb(248, 248, 248)"
  },
})

export default withStyles(styles)(LinkGenerator);