import React, { Component } from 'react';
import {
  withStyles,
  Box,
  Grid,
  WithStyles,
  Theme,
  createStyles,
  Typography,
  Button,
} from '@material-ui/core';
// import QRCode from "qrcode.react";
import { QRCode } from 'react-qrcode-logo';
import { copyTextToClipboard } from '../../helpers/clipboard';
import { NO_LABEL_URL } from '../../config/config';
const WhatsAppImage =
  'https://create.wa.link/static/WhatsApp-0e878a0fa68c61b06e781cee2e6bc71f.svg';
const AiSensyLogo =
  'http://aisensy-superadmin.s3-website.ap-south-1.amazonaws.com/favicon.ico';

class LinkDialogContent extends Component<Props, cState> {
  static defaultProps = {
    name: '',
    linkid: 'default value',
  };
  state = {
    countryCode: '',
    name: this.props.name,
    copied: false,
  };
  //   async componentDidMount(): Promise<void> {
  //     const clientId = '';
  //   }
  downloadQR = () => {
    const canvas = document.getElementById(
      'react-qrcode-logo',
    ) as HTMLCanvasElement;
    if (canvas) {
      const pngUrl = canvas
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream');
      const downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = this.props.linkid + '.png';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };
  clickToCopy = () => {
    const link = `${NO_LABEL_URL}/` + this.props.linkid;
    // navigator.clipboard.writeText(link);
    copyTextToClipboard(link);
    // Pop up alert that link has been copied to clipboard
    this.setState({ copied: true });
  };
  render(): React.ReactElement {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Typography variant="h3" style={{ color: 'black' }} paragraph>
          Here is your WhatsApp short link
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Copy and share it on your social media, website, emails or anywhere
          you want to be contacted instantly by your customers.
        </Typography>
        <Box pt={2}>
          <Grid container justify="center" alignItems="center">
            <Grid item>
              <img className={classes.whatsappimg} src={WhatsAppImage} />
            </Grid>
            <Grid item>
              <a
                href={`${NO_LABEL_URL}/` + this.props.linkid}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'underline' }}
              >
                <Typography variant="h3" color="primary" align="center">
                  {NO_LABEL_URL.split('https://')[1] + `/${this.props.linkid}`}
                </Typography>
              </a>
            </Grid>
          </Grid>
        </Box>
        <Box pt={2} mb={1} display="flex" justifyContent="center">
          <QRCode
            value={`${NO_LABEL_URL}/` + this.props.linkid}
            size={180}
            ecLevel={'H'}
            logoHeight={50}
            logoWidth={50}
          />
        </Box>
        {
          this.state.copied && (
            <Typography
              style={{ color: 'green' }}
              variant="body1"
              paragraph
              align="center"
            >
              Link has been copied to clipboard !!
            </Typography>
          )
          //  (
          //   <Typography
          //     color="textSecondary"
          //     variant="body1"
          //     paragraph
          //     align="center"
          //   >
          //     FREE !!- Track links & much more,{' '}
          //     <a href="https://aisensy.com" target="_blank" rel="noreferrer">
          //       Sign Up
          //     </a>{' '}
          //     now
          //   </Typography>
          // )
        }
        <Grid container justify="center" spacing={3}>
          <Grid item>
            {!this.state.copied ? (
              <Button
                variant="contained"
                color="primary"
                onClick={this.clickToCopy}
              >
                Copy URL
              </Button>
            ) : (
              <Button onClick={this.props.closeDialog}>Go Back</Button>
            )}
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={this.downloadQR}
            >
              Download QR
            </Button>
          </Grid>
        </Grid>
        {/* <Box py={2}>
					<Grid container alignItems="center">
						<Grid item xs>
							<Typography color="primary" variant="h4">
								Access premium features for FREE
							</Typography>
						</Grid>
						<Grid item>
							<Button color="primary"> Sign Up</Button>
						</Grid>
					</Grid>
				</Box> */}
      </div>
    );
  }
}

interface Props extends WithStyles<typeof styles> {
  name: string;
  linkid: string;
  closeDialog: () => void;
  // number: string
}

interface cState {
  countryCode: string;
  name: string;
  copied: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      minHeight: 500,
      backgroundColor: 'white',
      boxSizing: 'border-box',
      marginBottom: 10,
      padding: '20px',
    },
    whatsappimg: {
      height: 50,
      marginRight: 15,
    },
  });

export default withStyles(styles)(LinkDialogContent);
