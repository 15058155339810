import React, {Component} from 'react';
import { 
	withStyles, 
	Box, 
	Grid, 
	WithStyles, 
	createStyles, 
} from '@material-ui/core';
import LinkGenerator from './LinkGenerator';
import Navbar from '../../commons/Navbar/Navbar';
class WhatsAppLinkPage extends Component<Props, cState> {
	constructor(props: Props) {
		super(props)
		this.state = {}
	}
	render(): React.ReactElement {
		const { classes } = this.props
		return (
<>
			<Navbar />
			<div className={classes.root}>
				<Grid container justify="center">
					<Grid item xs={12} md={10} lg={7}>
						{/* Put all content in here */}
						<Box py={4}>
							<LinkGenerator />
						</Box>
					</Grid>
				</Grid>
			</div>
			</>
		)
	}
}

interface Props extends WithStyles<typeof styles> {
	// name: string,
	// number: string
}

interface cState {}

const styles = () => createStyles({
	"root": {
		width: "100%",
		backgroundColor: "rgb(254, 254, 254)",
		boxSizing: "border-box",
	},
})

export default withStyles(styles)(WhatsAppLinkPage);