import { ThemeOptions } from "@material-ui/core";
// import { AutocompleteClassKey } from "@material-ui/lab/Autocomplete";
import type {} from "@material-ui/lab/themeAugmentation";
// import * as createPalette from '@material-ui/core/styles/createPalette';
// import * as createPalette from ""
declare module '@material-ui/core/styles/createPalette' {
  interface PaletteOptions {    
    pending?: PaletteColorOptions;
    resolved?: PaletteColorOptions;
    backdrop?: PaletteColorOptions;
  }
  interface Palette {    
    pending: PaletteColor;
    resolved: PaletteColor;
    backdrop: PaletteColor;
  }
}

// declare module '@material-ui/core/styles/overrides' {
//   interface ComponentNameToClassKey {
//     MuiAutocomplete: AutocompleteClassKey
//   }
// }

const pending = "#e65100";
const resolved = "#388e3c";
const primary = "#4dc247";
const secondary = "#fec000";
const warning = "#FFC260";
const success = "#4caf50";
const error = "#f44336";
const info = "#9013FE";
// const backdrop = "#f6f7ff";
// const lightenRate = 7.5;
// const darkenRate = 15;
// const queryText = "#e0e0e0";

const defaults: ThemeOptions = {
  palette: {
    pending: {
      main: pending,
    },
    resolved: {
      main: resolved,
    },

    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
    warning: {
      main: warning,
    },
    success: {
      main: success,
    },
    error: {
      main: error,
    },
    info: {
      main: info,
    },
    text: {
      primary: "#4A4A4A",
      secondary: "#6E6E6E",
      hint: "#B9B9B9",
    },
    // background: {
    //   default: "#F6F7FF",
    //   light: "#F3F5FF",
    // },
    // backdrop: {
    //   default: "#f6f7ff",
    // },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 767,
      md: 959, // 1348
      lg: 1599, //1902
      xl: 1920,
    },
  },
  // shadows: ["none"],
  shape: {
    borderRadius: 6,
  },
  typography: {
    h1: {
      fontFamily: "Poppins",
      fontSize: 32,
      fontWeight: 700,
      letterSpacing: 1,
      wordSpacing: 2,
      "@media (max-width:600px)": {
        fontSize: 24,
        fontWeight: 700,
        wordSpacing: 2,
        letterSpacing: 1
      },
    },
    h2: {
      fontFamily: "Poppins",
      fontSize: 25,
      fontWeight: 700,
      letterSpacing: 1,
      wordSpacing: 2,
      "@media (max-width:600px)": {
        fontSize: 20,
        fontWeight: 700,
        wordSpacing: 2,
        letterSpacing: 1
      },
    },
    h3: {
      fontFamily: "Poppins",
      fontSize: 20,
      "@media (max-width:600px)": {
        fontSize: 18,
      },
    },
    h4: {
      fontFamily: "Poppins",
      fontSize: 16,
    },
    h5: {
      fontFamily: "Poppins",
      fontSize: 14,
      fontWeight: 400,
    },
    h6: {
      // fontFamily: "Poppins",
      fontSize: 12,
      fontWeight: 400,
    },
    body1: {
      fontFamily: "Roboto",
      fontSize: 14,
    },
    body2: {
      fontFamily: "Roboto",
      fontSize: 12,
    },
  },
  "overrides": {
    "MuiTextField": {
      root:{
        backgroundColor: "rgb(240,240,240)",
        borderRadius: "8px",
        boxSizing: "border-box",
        fontSize: "15px",
        fontWeight: 500,
        padding: "6px 15px"
      },
    },
    "MuiOutlinedInput": {
      root: {
        "& $notchedOutline": {
          border: "none"
        },
        "&:hover $notchedOutline": {
          border: "none"
        },
        "&$focused $notchedOutline": {
          border: "none"
        },
        "&&& $input": {
          padding: "12px"
        },
        "&&& $input:first-child": {
          padding: "12px"
        }
      },
      multiline: {
        padding: 0
      }
    },
    MuiAutocomplete: {
      inputRoot: {
        '&[class*="MuiOutlinedInput-root"].MuiAutocomplete-input': {
          padding: 12,
        },
        '&&[class*="MuiOutlinedInput-root"]': {
          padding: 0,
        },
      }
    },
    "MuiButton": {
      "root": {
        textTransform: "none"
      },
      "contained": {
        boxShadow: "none"
      },
      "containedPrimary": {
        "&:hover": {
          boxShadow: "none"
        }
      }
    },
    "MuiGrid": {
      container: {
      }
    },
    // "MuiTimelineItem": {
    //   // "missingOppositeContent": {
    //   //   "&:before": {
    //   //     flex: 0,
    //   //     padding: 0,
    //   //   }
    //   // }
    // },
    // "MuiTimelineDot": {
    //   root: {
    //     boxShadow: "none"
    //   }
    // },
    // MuiTimelineConnector: {
    //   root: {
    //     backgroundColor: primary
    //   }
    // }
  },
  props: {
    MuiInput: {
      disableUnderline: true
    },
    MuiPaper: {
      // elevation: 0
    },
    MuiTooltip: {
      enterTouchDelay: 0
    }
  }
};

export default defaults;
