import React, {Component} from 'react';

class NotFoundPage extends Component {
	render(): React.ReactElement {
		return (
			<div>
				404 Page
			</div>
		)
	}
}

export default NotFoundPage;