export const preFormat = (url: string): string => {
  // Ignore hashed value in url
  let formattedUrl = url.split('#')[0];

  // Add trailing /
  if (url.slice(-1) !== '/') {
    formattedUrl += '/';
  }
  // Add trailing / before ?
  const t = formattedUrl.split('?');
  if (t.length == 2) {
    // Replace & with /
    t[1] = t[1].split('&').join('/');
    // Replace ? with / if there isn't / before ?
    if (t[0].slice(-1) !== '/') {
      formattedUrl = t[0] + '/' + t[1];
    } else {
      formattedUrl = t[0] + t[1];
    }
  }
  formattedUrl = decodeURI(formattedUrl);
  return formattedUrl;
};

export const generateExp = (url: string): string => {
  // format url
  const formattedUrl = preFormat(url);
  let regEx = '';
  regEx = '^';
  regEx += formattedUrl
    .replace(/\{\{([a-z_]+)\}\}/g, 'AISENSY')
    .replace(/[-[\]{}()*+?.\/,\\^$|#\s/\\]/g, '\\$&')
    .replace(/AISENSY/g, '([^\\/]+)');
  regEx += '$';
  return regEx;
};

export const extractParams = (url: string): string[] => {
  // Only allowed param names a-z with _
  return url.match(/\{\{([a-z_]+)\}\}/g) || [];
};

export const extractParamsValue = (url: string, pattern: string): string[] => {
  // pre format url first
  const formattedUrl = preFormat(url);
  // match formatted url against regex
  const matched = formattedUrl.match(new RegExp(pattern));
  // except first element rest will be values matched
  // order of extracted values and extracted param will be same
  if (matched) {
    return matched.slice(1);
  } else {
    return [];
  }
};

export const validateUrl = (url: string): boolean => {
  // Check for http or https in begining
  return !!url.match(/^(http|https):\/\/*/);
};

// const sampleUrl = "http://aisensy.com/products/{{product_name}}/{{site}}"
// const url = "http://aisensy.com/products/hand-cleaner/alcohol";

// const formattedUrl = preFormat(url);
// console.log({ formattedUrl });
// const params = extractParams(formattedUrl);
// console.log("params: ", params);
