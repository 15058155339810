import { cState } from "../pages/WhatsAppBtnPage/ButtonGenerator";
import { entityType, ILink, whatsAppBtn, variantType } from "../types/link.type";
import { generateExp, validateUrl } from "./url";

export const formatAndValidateForm = (state: cState):ILink => {
  const s:cState = { ...state };
  if (!s.phoneNumber || s.phoneNumber.length < 5) {
    throw { message: "Use valid phone number", type: "ERR104" };
  }
  if (!s.onscreenMsg) {
    throw { message: "On screen message is required field", type: "ERR104" }
  }
  if (!s.brandName || !s.brandSub) {
    throw { message: "Brand name & subtitle are required fields", type: "ERR104" };
  }
  if (!s.brandImgUrl) {
    throw { message: "Provide your brand image url", type: "ERR104" };
  }
  if (s.brandColor.length !== 7) {
    s.brandColor = "#0A5F54";
  }
  if (s.btnBackground.length !== 7) {
    s.btnBackground = "#4dc247";
  }
  if (!s.urlPersonalization) {
    s.personalizedUrls = [];
  } else {
    // Validate empty fields in personalized urls
    s.personalizedUrls.forEach( e => {
      if (!e.url || !e.urlMsg || !e.userMsg) {
        throw { message: "Personalized-url fields can't be empty", type: "ERR104" }
      }
    })
    s.personalizedUrls.forEach( e => {
      if (!validateUrl(e.url)) {
        throw { message: "Url must start with https or http", type: "ERR104" }
      }
    })

    // Add regex

    // To care about
    // leading * - will match till immediate /
    // allowed variable names - defined chars (a-z, _)
    // allow url with only http or https
    // do not allow /, &, ? in param values - match to anything not allowed
    // ignore # hashed url part 
    // only considered ? query params after 
    // option for to consider query params or not while matching
    // if ?query speciafically mentioned in url then only consider that part
    // provide to values {{page_url}} {{page_title}} globally available
    // {{page_url}} to be with query params - no, use specific urls for that 
    // limit max num of allowed personalized urls
    // sample url
    // display extracted params in url boxes
    // url should always end with / - append if not (on backend also)
    // trim inputs
    // handle %20 in query params
    // display url with decodedURI() in inputs and other places
    // handle url after decodeURI() on backend also then apply format for removing - or _
    
    // Every url will be bounded by http & /
    // Only one param b/w / & / if no const value between them
    // Convert & in /

    // exmaple regex - /^((http|https)://)aisensy.com\/products\/*\//
    // example regex - replace {{param}} with *^(/|&) 


    // http://aisensy.com/products/hair-{{product-name}}-his/
    // http://aisensy.com/products/hair-{{product-name}}/
    // http://aisensy.com/products/{{product-name}}/
    // http://aisensy.com/products/{{product-name}}/{{fields}}/
    // http://aisensy.com/products/?utm_name={{campaign-name}}
    // http://aisensy.com/products/?utm_name={{campaign-name}}&utm_source={{}}
    // http://aisensy.com/products/?{{everything-in-query-params}}
    // http://aisensy.com/products?utm_name={{campaign-name}}
    // http://aisensy.com/{{doesn't matter what comes after this}} - not allowed
    // {{match to anything}} - not allowed
    // http://aisensy.{{country_domain}}/products
    // http://aisensy.com/products/
    // http://{{domain_name}}/products
    // https://{{domain_name}}/products
  }

  const whatsAppBtnValue: whatsAppBtn = {
		variant: variantType.btnWithWidget,
    btnBackground: s.btnBackground,
    btnCTA: s.btnCTA,
    mb: s.mb || "0",
    ml: s.ml || "0",
    mr: s.mr || "0",
    borderRadius: s.borderRadius || "0",
    position: s.position,
    brandName: s.brandName,
    brandSub: s.brandSub,
    brandColor: s.brandColor,
    brandImgUrl: s.brandImgUrl,
    widgetBtnCTA: s.widgetBtnCTA || "",
    openWidgetByDefault: s.openWidgetByDefault,
    openWidgetSessionWindow: s.openWidgetSessionWindow,
    onscreenMsg: s.onscreenMsg,
    widgetOnMobile: s.widgetOnMobile,
		urlPersonalization: s.urlPersonalization,
		personalizedUrls: s.personalizedUrls.map(e => {
      return { 
        url: e.url, 
        urlMsg: e.urlMsg, 
        userMsg: e.userMsg,
        toFormat: e.toFormat, 
        toCapitalize: e.toCapitalize,
        regEx: generateExp(e.url),
      }
    })
  }
  return {
    entityType: entityType.BTN,
    linkId: "",
    phoneNumber: s.phoneNumber,
    prefilledMsg: s.prefilledMsg,
    clientId: "",
    assistantId: "",
    whatsappBtnSettings: whatsAppBtnValue,
  }
}