import React, { Component } from 'react';
import {
  withStyles,
  Box,
  Grid,
  WithStyles,
  Theme,
  createStyles,
  Typography,
  Button,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { ArrowForward, PlayCircleOutline } from '@material-ui/icons';
import CustomCard from '../../commons/CustomCard/CustomCard';


import LinkBanner from '../../static/link_banner.png';
import ButtonBanner from '../../static/button_banner.png';
// import Explosion from '../../static/explosion.gif';
import MainBanner from '../../static/store.png';
import IntegrationBanner from "../../static/integration_banner.png";
import Navbar from '../../commons/Navbar/Navbar';

class HomePage extends Component<Props, cState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      name: '',
      main: '',
    };
  }
  render(): React.ReactElement {
    const { classes } = this.props;
    return (
      <>
      <Navbar />
      <div className={classes.root}>
        <div className={classes.ctaContainer}>
          <div className={classes.overlay}></div>
          <div style={{ height: '100%', zIndex: 5, position: 'relative' }}>
          <Grid container justify="center" style={{ height: '100%', zIndex: 5 }}>
            <Grid item xs={12} md={10} lg={7} style={{ height: '100%' }}>
              {/* Put all content in here */}
              <Grid container alignItems="center" direction="column" justify='center'>
                <Grid item>
                  <Typography variant="h1" paragraph>
                    Tools that Drive Users to Whatsapp
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h4" style={{color: "#000000c2"}}>
                    Create Unlimited FREE Whatsapp Links and URL Personalised Button for Website
                  </Typography>
                </Grid>
                {/* <Grid item>
                  <Box pt={2}>
                    <Box
                      color="primary"
                      className={
                        "wistia_embed wistia_async_" +
                        "cqy3g0nwj9" +
                        " popover=true popoverContent=link wistia_embed_initialize playerColor=04454D"
                      }
                    >
                      <Button 
                        startIcon={<PlayCircleOutline fontSize="large" />} 
                        color="primary"
                        size="large"
                        style={{borderRadius: "20px"}}
                        variant="contained"
                      >
                        How it works ?
                      </Button>
                    </Box>
                  </Box>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
          </div>
        </div>
        <Grid container justify="center">
          <Grid item xs={12} md={10} lg={7}>
            {/* Put all content in here */}
            <Grid container justify="space-around">
              <Grid item xs={12} md={5}>
                <Box p={2} pb={4}>
                  <CustomCard 
                    heading="WhatsApp Link"
                    body="Share your WhatsApp contact with others as link with prefilled user messages and..."
                    banner={LinkBanner}
                    btnCTA="Generate Link"
                    url="/tools/generate-whatsapp-link"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <Box p={2} pb={4}>
                  <CustomCard 
                    heading="WhatsApp Button"
                    body2='Git is a distributed version control system. Every dev has a working copy of the code and...'
                    body="Create beautiful WhatsApp button with widget for your website. URL personalization and..."
                    banner={ButtonBanner}
                    btnCTA="Generate Button"
                    url="/tools/generate-whatsapp-button"
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
    
        {/* <Grid container justify="center" style={{margin: "8% 0% 0% 0%", backgroundColor: "rgb(245, 245, 245)"}} > */}
          {/* <Grid item xs={12} md={10} lg={7} > */}
            {/* Put all content in here */}
            {/* <Grid 
              container 
              alignItems="center" 
              justify="space-between"
              className={classes.integrationsSection}
            >
              <Grid item xs={12} md={5}>
                <img src={IntegrationBanner} className={classes.integrationBanner} />
              </Grid>
              <Grid item xs={12} md={5}>
                <Typography variant="h2" gutterBottom>
                  Explore our Marketplace Integrations
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Extend your existing marketing channels to WhatsApp
                </Typography>
                <Button 
                  color="primary"
                  size="large"
                  style={{marginTop: "20px"}}
                  variant="outlined"
                  href="https://m.aisensy.com/integrations/"
                  target="_blank"
                  className={classes.hrefBtn}
                >
                  Explore Now
                </Button>
              </Grid>
              
            </Grid> */}
          {/* </Grid> */}
        {/* </Grid> */}
      </div>
      </>
    );
  }
}

interface cState {
  main: string;
  name: string;
}

interface Props extends WithStyles<typeof styles> {
  name: string;
  work: string;
  componentId: number;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      backgroundColor: 'white',
      minHeight: '100vh',
    },
    ctaContainer: {
      width: '100%',
      position: 'relative',
      boxSizing: 'border-box',
      // backgroundImage: `url('${Explosion}')`,
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      margin: "0% 0% 0% 0%",
      padding: '4% 0% 0% 0%',
      // backgroundColor: '#EBF5F3',
      height: '30vh',
      // color: "white",
      [theme.breakpoints.down('sm')]: {
        // height: "60vh",
        marginTop: "5%",
        padding:`15px 15px`
      }
    },
    overlay: {
      position: "absolute",
      height: "100%",
      width: "100%",
      left: 0,
      top: 0,
      background: "#bbc1b754",
      zIndex: 0,
      display: "none"
    },
    banner: {
      display: "inline-block",
      height: 240,
      width: "100%",
      background: "rgb(245, 245, 245)",
      // borderRadius: "8px",
      overflow: "hidden",
      // border: "2px solid #0a474c",
      marginBottom: 30
    },
    integrationBanner: {
      maxHeight: "400px",
      [theme.breakpoints.down('sm')]: {
        maxHeight: "300px"
      }
    },
    mainBanner: {
      height: "200px"
    },
    hrefBtn: {
      color: `${theme.palette.primary.main} !important`
    },
    integrationsSection: {
      padding: "6% 0%"
    }
    // "signupForm": {
    // 	borderRadius: 15,
    // 	backgroundColor: theme.palette.primary.main,
    // 	color: "white",
    // 	marginTop: "10vh",
    // 	height: "30vh"
    // }
  });

export default withStyles(styles)(HomePage);
