import React, { Component } from 'react';
import {
  withStyles,
  Box,
  Grid,
  WithStyles,
  Theme,
  createStyles,
  Typography,
  Button,
  Popover,
} from '@material-ui/core';

import Picker, { IEmojiData } from 'emoji-picker-react';

class EPicker extends Component<Props, cState> {
  static defaultProps = {
    color: 'AiSensy',
    fieldName: ''
  };
  state = {
    countryCode: '',
    name: this.props.color,
    copied: false,
    anchorEl: null
  };
  handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  }
  handleClose = () => {
    this.setState({ anchorEl: null });
  }
  emojiHandler = (event: React.MouseEvent<Element, MouseEvent>, data: IEmojiData) => {
    this.props.setEmoji(event, data, this.props.fieldName );
  }
  // async componentDidMount(): Promise<void> {}
  render(): React.ReactElement {
    const { classes } = this.props;
    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
      <div className={classes.root} >
        <div 
          className={classes.root} 
          onClick={this.handleClick}
        >
          😊
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <div className={classes.demopicker}>
            {/* <HexColorPicker color={this.props.color} onChange={this.props.setColor} /> */}
						<Picker onEmojiClick={this.emojiHandler} />
          </div>
        </Popover>
      </div>
    );
  }
}

interface Props extends WithStyles<typeof styles> {
  color: string;
  fieldName: string;
  setEmoji: (event: React.MouseEvent<Element, MouseEvent>, data: IEmojiData, fieldName: string) => void;
  // number: string
}

interface cState {
  countryCode: string;
  name: string;
  copied: boolean;
  anchorEl: HTMLDivElement | null
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: 20,
      height: 20,
      borderRadius: "50%",
      cursor: "pointer",
      // border: `1px solid ${theme.palette.primary.main}`,
      display: 'flex',
      justifyContent: "center",
      fontSize: 20,
      lineHeight: 1,
			// background: theme.palette.primary.main,
      boxShadow: "rgb(0 0 0 / 20%) 0px 0px 0.428571rem 0px",
      // color: 'white'
    },
    demopicker: {
      // width: 200,
      // height: 200,
      background: "transparent",
      overflow: "hidden"
    }
  });

export default withStyles(styles)(EPicker);
