import React from 'react';
import cx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import { useBlogTextInfoContentStyles } from '@mui-treasury/styles/textInfoContent/blog';
import { useOverShadowStyles } from '@mui-treasury/styles/shadow/over';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    margin: 'auto',
    borderRadius: spacing(2), // 16px
    transition: '0.3s',
    boxShadow: '0px 0px 40px rgba(34, 35, 58, 0.2)',
    position: 'relative',
    maxWidth: 500,
    marginLeft: 'auto',
    overflow: 'initial',
    background: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: spacing(2),
    [breakpoints.up('md')]: {
      flexDirection: 'row',
      paddingTop: spacing(2),
      boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
    },
  },
  media: {
    width: '88%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: spacing(-3),
    height: 0,
    paddingBottom: '48%',
    borderRadius: spacing(2),
    backgroundColor: '#fff',
    position: 'relative',
    [breakpoints.up('md')]: {
      width: '100%',
      marginLeft: spacing(-3),
      marginTop: 0,
      transform: 'translateX(-8px)',
    },
    '&:after': {
      content: '" "',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      // backgroundImage: 'linear-gradient(147deg, #7b966659 0%, #05921057 54%)',
      borderRadius: spacing(2), // 16
      opacity: 0.5,
    },
  },
  content: {
    padding: 24,
  },
  cta: {
    marginTop: 24,
    textTransform: 'initial',
  },
  button: {
    // backgroundImage: 'linear-gradient(147deg, #ffc100 0%, #059210 74%)',
    backgroundColor: "#ffc100",
    boxShadow: '0px 4px 32px #28ad3338'
  }
}));

export const BlogCardDemo = (props) =>{
  const {
    heading,
    body,
    banner,
    btnCTA,
    url
  } = props;

  const Layered =  React.memo(function BlogCard() {
    const styles = useStyles();
    const {
      button: buttonStyles,
      ...contentStyles
    } = useBlogTextInfoContentStyles();
    const shadowStyles = useOverShadowStyles();
    return (
      <Card className={cx(styles.root, shadowStyles.root)}>
        <CardMedia
          className={styles.media}
          image={banner}
        />
        <CardContent>
          <TextInfoContent
            classes={contentStyles}
            heading={heading}
            body={body}
          />
          <Button className={cx(buttonStyles, styles.button)} component={RouterLink} to={url}>
            {btnCTA}
          </Button>
        </CardContent>
      </Card>
    );
  });
  return <Layered />
}

export default BlogCardDemo