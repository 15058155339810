const URL =
  process.env.REACT_APP_LINK_GEN_BACKEND_URL ||
  (process.env.REACT_APP_NODE_ENV === 'production'
    ? 'https://wa.aisensy.com'
    : 'https://wa.aisensy.com');

const NO_LABEL_URL =
  process.env.REACT_APP_NO_LABEL_LINK_GEN_BACKEND_URL ||
  (process.env.REACT_APP_NODE_ENV === 'production'
    ? 'https://api-wa.co'
    : 'https://api-wa.co');

const GOOGLE_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_CLIENT_ID ||
  '882865407668-dl8a107bcb65kl8ttqe8e6j2usu3cm40.apps.googleusercontent.com';

const TENANT_ID = process.env.REACT_APP_TENANT_ID;

export { URL, NO_LABEL_URL, GOOGLE_CLIENT_ID, TENANT_ID };
