import React, { Component } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { withRouter } from 'react-router';
import {
  withStyles,
  Box,
  Grid,
  WithStyles,
  Theme,
  createStyles,
  Typography,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
  IconButton,
  Zoom,
  Tooltip,
  CircularProgress,
  Dialog,
  Checkbox,
  InputAdornment,
  Hidden,
} from '@material-ui/core';
import 'emoji-mart/css/emoji-mart.css';
import { Add, Clear, Info, PlayCircleOutline } from '@material-ui/icons';
import axios, { AxiosError } from 'axios';

import { NO_LABEL_URL, URL } from '../../config/config';
import { getInstamojoConfig } from '../../helpers/instamojoConfig';
import { variantType, widgetSessionWindowType } from '../../types/link.type';
import WidgetDialogContent from './WidgetDialogContent';
import { formatAndValidateForm } from '../../helpers/formatAndValidateWidgetForm';
import { extractParams } from '../../helpers/url';
import ColorPicker from "../../commons/ColorPicker/ColorPicker";
import { IEmojiData } from 'emoji-picker-react';
import EPicker from '../../commons/EPicker/EPicker';
import { RouteComponentProps, RouterProps } from 'react-router-dom';

class ButtonGenerator extends Component<Props, cState> {
  static defaultProps = {
    // name: 'AiSensy',
  };
  private prefilledMsgTextArea: React.RefObject<HTMLTextAreaElement> = React.createRef();
  private onscreenMsgTextArea: React.RefObject<HTMLTextAreaElement> = React.createRef();

  state = {
    env: 'dev',
    isSubmitting: false,
    errmsg: '',
    linkid: '',
    phoneNumber: '',
    variant: variantType.btnWithWidget,
    btnBackground: '#4dc247',
    btnCTA: 'Chat with us',
    mb: '30',
    ml: '30',
    mr: '30',
    borderRadius: '24',
    prefilledMsg: 'Hi',
    position: 'Bottom-Right',
    brandName: '',
    brandSub: 'online',
    brandColor: '#0A5F54',
    brandImgUrl: '',
    widgetBtnCTA: 'Start chat',
    openWidgetByDefault: 'true',
    openWidgetSessionWindow: widgetSessionWindowType.always,
    onscreenMsg: "Hi,\nHow can I help you ?",
    widgetOnMobile: 'true',
    urlPersonalization: false,
    personalizedUrls: [
      {
        url: '',
        urlMsg: '',
        userMsg: '',
        key: 0,
        toFormat: true,
        toCapitalize: true,
        prefilledRef: React.createRef<HTMLTextAreaElement>(),
        onscreenRef: React.createRef<HTMLTextAreaElement>()
      },
    ],
  };
  async componentDidMount(): Promise<void> {
    // Get query params from here
    const queryString: string = this.props.history.location.search;
    const searchParams = new URLSearchParams(queryString);
    const src = searchParams.get("src");
    const store_domain = searchParams.get("store_domain");
    if (src === "instamojo" && store_domain) {
      const config = getInstamojoConfig(store_domain);
      this.setState({ ...config });
    }
  }
  isSrcInstamojo = () => {
    const queryString: string = this.props.history.location.search;
    const searchParams = new URLSearchParams(queryString);
    const src = searchParams.get("src");
    const store_domain = searchParams.get("store_domain");
    if (src === "instamojo" && store_domain) {
      return true;
    }
    return false;
  }
  handleTextInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e.target.name;
    this.setState({ ...this.state, [key]: e.target.value });
  };
  handleRadioInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e.target.name;
    this.setState({ ...this.state, [key]: e.target.value });
  };
  toggleUrlPersonalization = () => {
    this.setState({ urlPersonalization: !this.state.urlPersonalization });
  };
  removeRow = (index: number) => {
    this.setState({
      personalizedUrls: this.state.personalizedUrls.filter(
        (e, i) => i != index,
      ),
    });
  };
  addRow = () => {
    const { personalizedUrls } = this.state;
    const temp = {
      url: '',
      urlMsg: '',
      userMsg: '',
      toFormat: true,
      toCapitalize: true,
      // increment and add key for transition group
      key: personalizedUrls[0]['key'] + 1,
      prefilledRef: React.createRef<HTMLTextAreaElement>(),
      onscreenRef: React.createRef<HTMLTextAreaElement>()
    };
    this.setState({ personalizedUrls: [temp, ...this.state.personalizedUrls] });
  };
  handlePersonalizationInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ) => {
    // console.log(e, i)
    const temp = [...this.state.personalizedUrls];
    temp[index] = { ...temp[index], [e.target.name]: e.target.value };
    this.setState({ personalizedUrls: temp });
  };
  handlePersonalizedUrlFormatting = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const temp = [...this.state.personalizedUrls];
    temp[index] = { ...temp[index], [e.target.name]: e.target.checked };
    this.setState({ personalizedUrls: temp });
  };
  componentDidUpdate(prevProps: Props, prevState: cState) {
    // console.log(prevState, prevProps);
    // Update widget preview
    if (prevState != this.state) {
      if(typeof window.CreateWhatsAppButtonAndWidget === "function") {
        window.CreateWhatsAppButtonAndWidget({ ...this.state });
      }
    }
  }
  generateWidget = async (): Promise<void> => {
    try {
      this.setState({ isSubmitting: !this.state.isSubmitting });
      const data = formatAndValidateForm(this.state);
      type createResp = {
        linkId: string;
      };
      const res = await axios.post<createResp>(URL + '/create', data);
      this.setState({
        linkid: res.data.linkId,
        isSubmitting: false,
        errmsg: '',
      });
    } catch (err:any) {
      console.log(err);
      type errResp = {
        message: string;
        type: string;
      };
      // Handle axios error
      if (err && err.response) {
        const axiosError = err as AxiosError<errResp>;
        this.setState({
          isSubmitting: false,
          errmsg:
            axiosError.response?.data.message ||
            'Something went wrong, Try again !',
        });
        return;
      }
      // Handle direct in-function error
      if (err && err.message) {
        this.setState({
          errmsg: err.message,
          isSubmitting: false,
        });
        return;
      }
      // Fallback to generic message
      this.setState({
        errmsg: 'Something went wrong, Try again !',
        isSubmitting: false,
      });
    }
  };
  closeDialog = () => {
    this.setState({ linkid: '' });
  };
  setColor = (value: string, name: string) => {
    this.setState({ ...this.state, [name]: value });
  }
  setEmoji = (event: React.MouseEvent<Element, MouseEvent>, data: IEmojiData, fieldName: string) => {
    const ta = fieldName === "prefilledMsg" ? this.prefilledMsgTextArea : this.onscreenMsgTextArea;
    const t = fieldName as keyof cState; 
		
    if (ta.current !== null ) {
			// console.log(ta.current?.selectionEnd, ta.current?.selectionStart);
      const msg = String(this.state[t]);
			const cursorPos = ta.current?.selectionEnd || msg.length;
			const selectionStart = ta.current?.selectionStart || msg.length;
			const start = msg.substring(0, selectionStart);
			const end = msg.substring(selectionStart);
			const text = start + data.emoji + end;
			this.setState({...this.state, [fieldName]: text }, () => {
				// ta.current?.focus();
				if (ta.current) {
					ta.current.selectionEnd = cursorPos + 2;
					ta.current.selectionStart = cursorPos + 2;
				}
				// console.log("After update", ta.current?.selectionEnd, ta.current?.selectionStart);
			});

		} else {
      this.setState({...this.state, [fieldName]: this.state[t] + data.emoji });
    }
	}
  setEmojiUrl = (event: React.MouseEvent<Element, MouseEvent>, data: IEmojiData, fieldName: string) => {
		// this.setState({ msg: this.state.msg + data.emoji});
    const t = fieldName.split("-")[0] as keyof personalizedUrl;
    const index = Number(fieldName.split("-")[1]); 
    const temp = [...this.state.personalizedUrls];
    const urlObj = temp[index];
    const ta = t === "userMsg" ? urlObj.prefilledRef : urlObj.onscreenRef;
    
    if (ta.current !== null ) {
			// console.log(ta.current?.selectionEnd, ta.current?.selectionStart);
      const msg = String(urlObj[t]);
			const cursorPos = ta.current?.selectionEnd || msg.length;
			const selectionStart = ta.current?.selectionStart || msg.length;
			const start = msg.substring(0, selectionStart);
			const end = msg.substring(selectionStart);
			const text = start + data.emoji + end;
      temp[index] = { ...temp[index], [t]: text };
      this.setState({ personalizedUrls: temp }, () => {
				// ta.current?.focus();
				if (ta.current) {
					ta.current.selectionEnd = cursorPos + 2;
					ta.current.selectionStart = cursorPos + 2;
				}
				console.log("After update", ta.current?.selectionEnd, ta.current?.selectionStart);
			});

		} else {
      temp[index] = { ...temp[index], [t]: temp[index][t] + data.emoji };
      this.setState({ personalizedUrls: temp });
    }

  }
  handleColorInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e.target.name;
    let value = e.target.value.trim();
    value = value[0] !== '#' ? '#' + value : value;
    // if (key === "btnBackground") {
    //   value = value === '#' ? "#4dc247" : value; 
    // } else {
    //   value = value === '#' ? "#0A5F54" : value; 
    // }
    this.setState({ ...this.state, [key]: value });
  };
  // openColorPicker = (id: string) => {
  //   this.setState({});
  // }
  render(): React.ReactElement {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Box mx={{xs: 2, md: 0}} py={{xs:2, md: 4}} px={{xs: 2, md:4}} mb={3} className={classes.guideContainer}>
          <Typography variant="h4" color="primary" paragraph style={{fontWeight: "bold"}}>
            Create WhatsApp Button
          </Typography>
          {
            !this.isSrcInstamojo() ?
              <Typography variant="body1" color="textSecondary" paragraph>
                Install the button on your website via simple JavaScript code. (Click on Generate Snippet to get the installation code for your website) 
                <br/>
                Embed the generated script in footer of your webpage(recommended).
              </Typography>
            :
              <Typography variant="body1" color="textSecondary" paragraph>
                Customize the widget for your store.
                <br/>
                Copy the generated widget-id to install the widget on your store.
              </Typography>
          }
          <Typography variant="h5" gutterBottom>
            Check live preview of your WhatsApp Button at Bottom-Right of this page
          </Typography>
          {/* <Box pt={2}>
            <Box
              color="primary"
              className={
                "wistia_embed wistia_async_" +
                "cqy3g0nwj9" +
                " popover=true popoverContent=link wistia_embed_initialize playerColor=04454D"
              }
            >
              <Button startIcon={<PlayCircleOutline />} color="primary">
                Watch Tutorial
              </Button>
            </Box>
          </Box> */}
        </Box>
        <Box py={4}/>
        <Box py={2}  pl={{ xs: 2 }}>
          <Grid container spacing={4} justify="space-between">
            {/* <Grid item xs={12} md={4}>
              <Typography variant="h4" gutterBottom>
								Variant
							</Typography>
							<Typography 
                variant="body2" 
                color="textSecondary" 
                gutterBottom
                style={{width: "80%"}}
              >
								{`You can personalize the user message based on your website's URL`}
							</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <RadioGroup
                name="variant"
                value={this.state.variant}
                onChange={this.handleRadioInput}
                style={{display: "flex"}}
                row
              >
                <FormControlLabel
                  value={"WhatsAppBtn"}
                  control={<Radio color="primary" />}
                  label="WhatsApp Button"
                />
                <FormControlLabel
                  value={"WhatsAppBtnAndChatWidget"}
                  control={<Radio color="primary" />}
                  label="WhatsApp Butoton & Widget"
                />
              </RadioGroup>
            </Grid> */}
          </Grid>
          <Grid container>
            <Grid item xs={12} md={4}>
              <Typography variant="h4" gutterBottom>
                Chat Button
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                gutterBottom
                style={{ width: '80%' }}
              >
                {`You can personalize the user message based on your website's URL`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={8} container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Box display="flex" alignItems="flex-end" mb={1}>
                  <Typography variant="body2">WhatsApp Phone Number</Typography>
                  <Tooltip
                    placement="top"
                    title="Your WhatsApp number on which user will be redirected with pre-filled message."
                  >
                    <Info className={classes.infoIcon} />
                  </Tooltip>
                </Box>
                <TextField
                  fullWidth
                  type="text"
                  placeholder="Enter number with dial-code"
                  name="phoneNumber"
                  onChange={this.handleTextInput}
                  value={this.state.phoneNumber}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} className="color-picker-cont">
                <Box display="flex" alignItems="flex-end" mb={1}>
                  <Typography variant="body2">Button Background</Typography>
                  <Tooltip
                    placement="top"
                    title="Color to used for WhatsApp button. Default is #4dc247."
                  >
                    <Info className={classes.infoIcon} />
                  </Tooltip>
                </Box>
                {/* <ColorPicker
                  defaultValue="#4dc247"
                  onChange={this.handleBtnColor}
                  value={this.state.btnBackground}
                /> */}
                <TextField
                  fullWidth
                  type="text"
                  placeholder="Pick a color"
                  name="btnBackground"
                  value={this.state.btnBackground}
                  onChange={this.handleColorInput}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ColorPicker 
                          color={this.state.btnBackground} 
                          setColor={(value) => this.setColor(value, 'btnBackground')}                         
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box display="flex" alignItems="flex-end" mb={1}>
                  <Typography variant="body2">CTA Text</Typography>
                  <Tooltip
                    placement="top"
                    title="CTA text to displayed in WhatsApp button. ex - Chat with us"
                  >
                    <Info className={classes.infoIcon} />
                  </Tooltip>
                </Box>
                <TextField
                  fullWidth
                  type="text"
                  placeholder="Enter button CTA"
                  name="btnCTA"
                  onChange={this.handleTextInput}
                  value={this.state.btnCTA}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box display="flex" alignItems="flex-end" mb={1}>
                  <Typography variant="body2">Margin Bottom(px)</Typography>
                  <Tooltip
                    placement="top"
                    title="Margin from the bottom, Default is 30px."
                  >
                    <Info className={classes.infoIcon} />
                  </Tooltip>
                </Box>
                <TextField
                  fullWidth
                  type="number"
                  placeholder="Enter bottom margin"
                  name="mb"
                  onChange={this.handleTextInput}
                  value={this.state.mb}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box display="flex" alignItems="flex-end" mb={1}>
                  <Typography variant="body2">Margin Left(px)</Typography>
                  <Tooltip
                    placement="top"
                    title="Margin from the left, Default is 30px."
                  >
                    <Info className={classes.infoIcon} />
                  </Tooltip>
                </Box>
                <TextField
                  fullWidth
                  type="number"
                  placeholder="Enter left margin"
                  name="ml"
                  onChange={this.handleTextInput}
                  value={this.state.ml}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box display="flex" alignItems="flex-end" mb={1}>
                  <Typography variant="body2">Margin Right(px)</Typography>
                  <Tooltip
                    placement="top"
                    title="Margin from the right. Default is 30px."
                  >
                    <Info className={classes.infoIcon} />
                  </Tooltip>
                </Box>
                <TextField
                  fullWidth
                  type="number"
                  placeholder="Enter right margin"
                  name="mr"
                  onChange={this.handleTextInput}
                  value={this.state.mr}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box display="flex" alignItems="flex-end" mb={1}>
                  <Typography variant="body2">Border Radius(px)</Typography>
                  <Tooltip
                    placement="top"
                    title="Border radius of WhatsApp button. Default is 24px."
                  >
                    <Info className={classes.infoIcon} />
                  </Tooltip>
                </Box>
                <TextField
                  fullWidth
                  type="number"
                  placeholder="Enter border radius"
                  name="borderRadius"
                  onChange={this.handleTextInput}
                  value={this.state.borderRadius}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <Box display="flex" alignItems="flex-end" mb={1}>
                  <Typography variant="body2">Default Pre-filled Message</Typography>
                  <Tooltip
                    placement="top"
                    title="User message to be pre-filled when redirected to WhatsApp."
                  >
                    <Info className={classes.infoIcon} />
                  </Tooltip>
                  <Box mx={1} />
                  <EPicker setEmoji={this.setEmoji} fieldName="prefilledMsg"/>
                </Box>
                <TextField
                  multiline
                  rows={3}
                  fullWidth
                  type="text"
                  placeholder="Enter user's pre-filled message"
                  name="prefilledMsg"
                  onChange={this.handleTextInput}
                  value={this.state.prefilledMsg}
                  inputRef={this.prefilledMsgTextArea}
                />
                <Box mt={1}>
                  <Typography variant="body2" color="textSecondary">
                    You can use defaults - {`{{page_url}} & {{page_title}}`}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box display="flex" alignItems="flex-end" mb={1}>
                  <Typography variant="body2">Position</Typography>
                  <Tooltip
                    placement="top"
                    title="Position of the widget and button"
                  >
                    <Info className={classes.infoIcon} />
                  </Tooltip>
                </Box>
                <RadioGroup
                  name="position"
                  value={this.state.position}
                  onChange={this.handleRadioInput}
                  style={{ display: 'flex', fontSize: '10px' }}
                  row
                >
                  <FormControlLabel
                    value={'Bottom-Right'}
                    control={<Radio color="primary" />}
                    label={
                      <Typography variant="body2">Bottom-Right</Typography>
                    }
                  />
                  <FormControlLabel
                    value={'Bottom-Left'}
                    control={<Radio color="primary" />}
                    label={<Typography variant="body2">Bottom-Left</Typography>}
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
          {this.state.variant != 'WhatsAppBtn' ? (
            <React.Fragment>
              <Box py={4} />
              <Grid container>
                <Grid item xs={12} md={4}>
                  <Typography variant="h4" gutterBottom>
                    Chat Widget
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                    style={{ width: '80%' }}
                  >
                    {`You can personalize the user message based on your website's URL`}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={8} container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box display="flex" alignItems="flex-end" mb={1}>
                      <Typography variant="body2">BrandName</Typography>
                      <Tooltip
                        placement="top"
                        title="Your brand name to be displayed in the widget."
                      >
                        <Info className={classes.infoIcon} />
                      </Tooltip>
                    </Box>
                    <TextField
                      fullWidth
                      type="text"
                      placeholder="Enter brand name"
                      name="brandName"
                      onChange={this.handleTextInput}
                      value={this.state.brandName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box display="flex" alignItems="flex-end" mb={1}>
                      <Typography variant="body2">Brand Subtitle</Typography>
                      <Tooltip
                        placement="top"
                        title="Status of brand to be displayed to the user. ex. - Typically replies in minutes."
                      >
                        <Info className={classes.infoIcon} />
                      </Tooltip>
                    </Box>

                    <TextField
                      fullWidth
                      type="text"
                      placeholder="Enter subtitle here"
                      name="brandSub"
                      onChange={this.handleTextInput}
                      value={this.state.brandSub}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} className="color-picker-cont">
                    <Box display="flex" alignItems="flex-end" mb={1}>
                      <Typography variant="body2">Brand Color</Typography>
                      <Tooltip
                        placement="top"
                        title="Pick color which resonates with the primary color in your website."
                      >
                        <Info className={classes.infoIcon} />
                      </Tooltip>
                    </Box>
                    <TextField
                      fullWidth
                      type="text"
                      placeholder="Pick a color"
                      name="brandColor"
                      value={this.state.brandColor}
                      onChange={this.handleColorInput}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <ColorPicker 
                              color={this.state.brandColor || "#0A5F54"} 
                              setColor={(value) => this.setColor(value, 'brandColor')}                         
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box display="flex" alignItems="flex-end" mb={1}>
                      <Typography variant="body2">Brand Image Url</Typography>
                      <Tooltip
                        placement="top"
                        title="Profile image of brand to displayed in the widget."
                      >
                        <Info className={classes.infoIcon} />
                      </Tooltip>
                    </Box>
                    <TextField
                      fullWidth
                      type="text"
                      placeholder="Enter image url"
                      name="brandImgUrl"
                      onChange={this.handleTextInput}
                      value={this.state.brandImgUrl}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box display="flex" alignItems="flex-end" mb={1}>
                      <Typography variant="body2">Widget CTA Text</Typography>
                      <Tooltip
                        placement="top"
                        title="CTA text to be displayed inside the widget."
                      >
                        <Info className={classes.infoIcon} />
                      </Tooltip>
                    </Box>
                    <TextField
                      fullWidth
                      type="text"
                      placeholder="Enter widget CTA"
                      name="widgetBtnCTA"
                      onChange={this.handleTextInput}
                      value={this.state.widgetBtnCTA}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={8}>
                    <Box display="flex" alignItems="flex-end" mb={1}>
                      <Typography variant="body2">Default On-screen Message</Typography>
                      <Tooltip
                        placement="top"
                        title="Business message to be displayed in the widget."
                      >
                        <Info className={classes.infoIcon} />
                      </Tooltip>
                      <Box mx={1} />
                      <EPicker setEmoji={this.setEmoji} fieldName="onscreenMsg"/>
                    </Box>
                    <TextField
                      multiline
                      rows={3}
                      fullWidth
                      type="text"
                      placeholder="Enter user's pre-filled message"
                      name="onscreenMsg"
                      onChange={this.handleTextInput}
                      value={this.state.onscreenMsg}
                      inputRef={this.onscreenMsgTextArea}
                    />
                    <Box mt={1}>
                      <Typography variant="body2" color="textSecondary">
                        You can use defaults - {`{{page_url}} & {{page_title}}`}
                      </Typography>
                    </Box>
                  </Grid>
                  <Hidden smUp>
                    <Grid item xs={12} sm={6} md={2}></Grid>
                  </Hidden>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box display="flex" alignItems="flex-end" mb={1}>
                      <Typography variant="body2">Open widget on Mobile screen</Typography>
                      <Tooltip
                        placement="top"
                        title="If checked No, User will be redirected to WhatsApp on button click."
                      >
                        <Info className={classes.infoIcon} />
                      </Tooltip>
                    </Box>
                    <RadioGroup
                      name="widgetOnMobile"
                      value={this.state.widgetOnMobile}
                      onChange={this.handleRadioInput}
                      style={{ display: 'flex', fontSize: '10px' }}
                      row
                    >
                      <FormControlLabel
                        value={'true'}
                        control={<Radio color="primary" />}
                        label={<Typography variant="body2">Yes</Typography>}
                      />
                      <FormControlLabel
                        value={'false'}
                        control={<Radio color="primary" />}
                        label={<Typography variant="body2">No</Typography>}
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Box display="flex" alignItems="flex-end" mb={1}>
                      <Typography variant="body2">Open widget by default</Typography>
                      <Tooltip
                        placement="top"
                        title="If checked No, Widget will open only after button click."
                      >
                        <Info className={classes.infoIcon} />
                      </Tooltip>
                    </Box>
                    <RadioGroup
                      name="openWidgetByDefault"
                      value={this.state.openWidgetByDefault}
                      onChange={this.handleRadioInput}
                      style={{ display: 'flex', fontSize: '10px' }}
                      row
                    >
                      <FormControlLabel
                        value={'true'}
                        control={<Radio color="primary" />}
                        label={<Typography variant="body2">Yes</Typography>}
                      />
                      <FormControlLabel
                        value={'false'}
                        control={<Radio color="primary" />}
                        label={<Typography variant="body2">No</Typography>}
                      />
                    </RadioGroup>
                  </Grid>
                  {
                    this.state.openWidgetByDefault == "true" ?
                      <Grid item xs={12} sm={6} md={4}>
                        <Box display="flex" alignItems="flex-end" mb={1}>
                          <Typography variant="body2"> Re-open widget by default </Typography>
                          <Tooltip
                            placement="top"
                            title="Once the user closes the widget, Set re-open behavior of opening widget by default on next time page load."
                          >
                            <Info className={classes.infoIcon} />
                          </Tooltip>
                        </Box>
                        <RadioGroup
                          name="openWidgetSessionWindow"
                          value={this.state.openWidgetSessionWindow}
                          onChange={this.handleRadioInput}
                          style={{ display: 'flex', fontSize: '10px' }}
                          row
                        >
                          <FormControlLabel
                            value={'ALWAYS'}
                            control={<Radio color="primary" />}
                            label={<Typography variant="body2">Always</Typography>}
                          />
                          <FormControlLabel
                            value={'24_HOUR'}
                            control={<Radio color="primary" />}
                            label={<Typography variant="body2">After 24 hour</Typography>}
                          />
                          {/* <FormControlLabel
                            value={'1_WEEK'}
                            control={<Radio color="primary" />}
                            label={<Typography variant="body2">After 1 Week</Typography>}
                          /> */}
                        </RadioGroup>
                      </Grid>
                    : ""
                  }
                </Grid>
              </Grid>
            </React.Fragment>
          ) : (
            ''
          )}
          <Box py={4} />
          <Grid container justify="space-between" className={classes.rightMargin} >
            <Grid item xs={12} md={4}>
              <Typography variant="h4" gutterBottom>
                URL Personalization{' '}
                <Switch
                  checked={this.state.urlPersonalization}
                  onChange={this.toggleUrlPersonalization}
                  classes={{ switchBase: classes.switchBase }}
                  color="primary"
                  size="medium"
                />
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                gutterBottom
                style={{ width: '80%' }}
              >
                {`You can personalize the user message based on your website's URL`}
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} container>
              {this.state.urlPersonalization ? (
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      startIcon={<Add />}
                      onClick={this.addRow}
                      color="primary"
                      variant="outlined"
                      size="small"
                    >
                      ADD URL
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                ''
              )}

              {this.state.urlPersonalization ? (
                <TransitionGroup component="ul">
                  {this.state.personalizedUrls.map((e, i) => {
                    return (
                      <Zoom key={e.key}>
                        <div className={classes.backgroundCont}>
                          <Grid container>
                            <Grid item xs container spacing={2}>
                              <Grid item xs={12} md={11}>
                                <Box display="flex" alignItems="flex-end" mb={1}>
                                  <Typography variant="body2">Source Url</Typography>
                                  <Tooltip
                                    placement="top"
                                    title={` 
                                      You can parameterize urls. ex. - ${NO_LABEL_URL}/products/{{product_name}}. 
                                      Only alphabets with _ is allowed as parameters name.
                                    `}
                                  >
                                    <Info className={classes.infoIcon} />
                                  </Tooltip>
                                </Box>
                                <TextField
                                  fullWidth
                                  type="text"
                                  placeholder="Source url"
                                  value={e.url}
                                  name="url"
                                  onChange={(t) =>
                                    this.handlePersonalizationInput(t, i)
                                  }
                                />
                                <Box mt={1}>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    Parameters -{' '}
                                    {'{{page_url}}, {{page_title}},'}{' '}
                                    {extractParams(e.url).map((e) => e + ', ')}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={11}>
                                <Box display="flex" alignItems="flex-end" mb={1}>
                                  <Typography variant="body2">Pre-filled Message</Typography>
                                  <Tooltip
                                    placement="top"
                                    title="User message to be pre-filled when directed to WhatsApp from the url."
                                  >
                                    <Info className={classes.infoIcon} />
                                  </Tooltip>
                                  <Box px={1} />
                                  <EPicker setEmoji={this.setEmojiUrl} fieldName={"userMsg-" + i }/>
                                </Box>
                                <TextField
                                  multiline
                                  fullWidth
                                  rows={2}
                                  type="text"
                                  placeholder="Pre-filled message"
                                  value={e.userMsg}
                                  name="userMsg"
                                  onChange={(t) =>
                                    this.handlePersonalizationInput(t, i)
                                  }
                                  inputRef={e.prefilledRef}
                                />
                              </Grid>
                              {this.state.variant != 'WhatsAppBtn' ? (
                                <Grid item xs={12} md={11} container>
                                  <Box display="flex" alignItems="flex-end" mb={1}>
                                    <Typography variant="body2">On-screen Message</Typography>
                                    <Tooltip
                                      placement="top"
                                      title="Business message to be displayed in the widget based on the page url."
                                    >
                                      <Info className={classes.infoIcon} />
                                    </Tooltip>
                                    <Box px={1} />
                                    <EPicker setEmoji={this.setEmojiUrl} fieldName={"urlMsg-" + i }/>
                                  </Box>
                                  <TextField
                                    multiline
                                    fullWidth
                                    rows={2}
                                    type="text"
                                    placeholder="Onscreen message"
                                    value={e.urlMsg}
                                    name="urlMsg"
                                    onChange={(t) =>
                                      this.handlePersonalizationInput(t, i)
                                    }
                                    inputRef={e.onscreenRef}
                                  />
                                </Grid>
                              ) : (
                                ''
                              )}
                              <Grid item xs={12}>
                                
                              </Grid>
                              <Grid item xs={12} md={11} container>
                                <Grid item xs={12}>
                                  <Box display="flex" alignItems="flex-end" mb={1}>
                                    <Typography variant="body2">Format parameters values</Typography>
                                    <Tooltip
                                      placement="top"
                                      title="Format settings will be applied to each parameter value."
                                    >
                                      <Info className={classes.infoIcon} />
                                    </Tooltip>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} container>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={e.toFormat}
                                        onChange={(k) =>
                                          this.handlePersonalizedUrlFormatting(
                                            k,
                                            i,
                                          )
                                        }
                                        name="toFormat"
                                        color="secondary"
                                      />
                                    }
                                    label="Remove - & _"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={e.toCapitalize}
                                        onChange={(k) =>
                                          this.handlePersonalizedUrlFormatting(
                                            k,
                                            i,
                                          )
                                        }
                                        name="toCapitalize"
                                        color="secondary"
                                      />
                                    }
                                    label="Capitilize first letter"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item>
                              <IconButton
                                onClick={() => this.removeRow(i)}
                                disabled={
                                  this.state.personalizedUrls.length == 1
                                }
                              >
                                <Clear />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </div>
                      </Zoom>
                    );
                  })}
                </TransitionGroup>
              ) : (
                <div className={classes.backgroundCont}>
                  <Typography variant="h5" color="primary">
                    Enable URL personalization feature
                  </Typography>
                </div>
              )}
            </Grid>
          </Grid>
          <Box py={4} />
          <Grid container alignItems="center" direction="column">
            <Grid item>
              <Typography
                gutterBottom
                variant="body2"
                color="error"
                align="center"
              >
                {this.state.errmsg}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant={this.state.isSubmitting ? 'outlined' : 'contained'}
                color="primary"
                onClick={this.generateWidget}
                disabled={this.state.isSubmitting}
                style={{ margin: '2% 0px 0px 0px', padding: '12px 30px', color: "white" }}
              >
                {this.state.isSubmitting ? (
                  <CircularProgress size={20} />
                ) : (
                  <Typography variant="h3">
                    {this.isSrcInstamojo() ? "Generate Widget" : "Generate Snippet"}
                  </Typography>
                )}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Dialog
          open={!!this.state.linkid}
          onClose={this.closeDialog}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth={true}
          disableBackdropClick
        >
          <WidgetDialogContent
            linkid={this.state.linkid}
            closeDialog={this.closeDialog}
            isSrcInstamojo={this.isSrcInstamojo()}
          />
        </Dialog>
      </div>
    );
  }
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any>{
  // name: string;
  // number: string
}

type personalizedUrl = {
  url: string;
  urlMsg: string;
  userMsg: string;
  toFormat: boolean;
  toCapitalize: boolean;
  key: number;
  prefilledRef: React.RefObject<HTMLTextAreaElement>;
  onscreenRef: React.RefObject<HTMLTextAreaElement>;
};

export interface cState {
  env: string;
  isSubmitting: boolean;
  errmsg: string;
  // Form fields
  linkid: string;
  phoneNumber: string;
  variant: variantType;
  btnBackground: string;
  btnCTA: string;
  mb: string;
  ml: string;
  mr: string;
  borderRadius: string;
  prefilledMsg: string;
  position: string;
  brandName: string;
  brandSub: string;
  brandColor: string;
  brandImgUrl: string;
  widgetBtnCTA: string;
  openWidgetByDefault: string;
  openWidgetSessionWindow: widgetSessionWindowType;
  onscreenMsg: string;
  widgetOnMobile: string;
  // Url personalization specific results
  urlPersonalization: boolean;
  personalizedUrls: personalizedUrl[];
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100% !important',
      backgroundColor: 'white',
      boxSizing: 'border-box',
      borderRadius: 8,
      padding: '25px 0px',
      transition: '1s',
      // border: "1px solid lightgrey",
      minHeight: 100,
      '& ul': {
        padding: 0,
        margin: 0,
        'list-style-type': 'none',
        width: '100%',
      },
    },
    textField: {
      width: 120,
      marginRight: 10,
    },
    multiTextField: {
      width: '70%',
    },
    switchBase: {
      color: 'lightgrey',
    },
    backgroundCont: {
      // background: "rgb(247, 247, 247)",
      border: '1px solid rgb(240, 240, 240)',
      width: '100%',
      borderRadius: '8px',
      boxSizing: 'border-box',
      padding: '20px 20px',
      marginTop: '20px',
    },
    infoIcon: {
      fontSize: '18px',
      color: 'grey',
      marginLeft: '5px',
    },
    colorBtn: {
      borderRadius: "50%",
      height: 30,
      width: 30
    },
    guideContainer: {
      background: "white",
      borderRadius: "8px",
      // border: "1px solid lightgrey"
      backgroundColor: "rgb(248, 248, 248)"
    },
    "rightMargin": {
      [theme.breakpoints.down('sm')]: {
        paddingRight: 16
      }
    }
  });

export default withStyles(styles)(withRouter(ButtonGenerator));
