import React, { Component } from 'react';
import { 
	withStyles, 
	Box, 
	Grid, 
	WithStyles, 
	Theme, 
	createStyles, 
	Typography,
	TextField,
	Button,
} from '@material-ui/core';
import { AccountCircle, ArrowForward, Send } from '@material-ui/icons';
import {RouteComponentProps} from "react-router";
import Navbar from '../../commons/Navbar/Navbar';

class MobileMock extends Component<Props, cState> {
	static defaultProps = {
	}
	state = {
    storeDomain: "",
		err: ""
	}
	handleTextInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ storeDomain: e.target.value });
	}
	handleNextClick = () => {
		console.log("Clicked on next");
		if (this.state.storeDomain) {
			const genWidgetUrl = `/generate-whatsapp-button?src=instamojo&store_domain=${this.state.storeDomain}`;
			this.props.history.push(genWidgetUrl);
		} else {
			this.setState({err: "Provide valid domain"});
		}
	}
	render(): React.ReactElement {
    const { classes } = this.props;
		return (
			<>
			<Navbar/>
			<div className={classes.root}>
				<Grid container justify="center" alignItems="center" direction="column" style={{height: "100%"}}>
					<Grid item>
						<Typography variant="h4" style={{fontWeight: "bold"}}>
							Instamojo Store Domain
						</Typography>
						<Box my={2} />
					</Grid>
					<Grid item>
						<Typography variant="body2" color="textSecondary">
							Enter your {"store's"} domain. If You have configured custom domain for your store then use that.
						</Typography>
						<Box my={2} />
					</Grid>
					<Grid item>
						<TextField
							type="text"
							placeholder="e.g. - store.instamojo.com, mystore.com"
							name="storeDomain"
							onChange={this.handleTextInput}
							value={this.state.storeDomain}
							style={{"width": "400px"}}
						/>
						<Box my={2} />
					</Grid>
					{
						this.state.err ? 
							<Grid item>
								<Typography variant="body2" color="error">
									{this.state.err}
								</Typography>
							</Grid>
						: ""
					}
					<Grid item>
						<Button
							endIcon={<ArrowForward />}
							variant="contained"
							color="primary"
							style={{color: "white"}}
							onClick={this.handleNextClick}
						>
							Next
						</Button>
					</Grid>
				</Grid>
      </div>
	  </>
    )
  }
}


interface Props extends WithStyles<typeof styles>, RouteComponentProps<any> {

}

interface cState {
	storeDomain: string,
	err: string
}

const styles = (theme: Theme) => createStyles({
	"root": {
		width: "100%",
    height: 500,
		backgroundColor: "white",
		boxSizing: "border-box",
    marginBottom: 10,
    padding: "12px 10px 16px 10px",
		[theme.breakpoints.down('sm')]: {
			height: 300
		}
	}
})

export default withStyles(styles)(MobileMock);