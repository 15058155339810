import React, { Component } from 'react';
import {
  withStyles,
  Box,
  Grid,
  WithStyles,
  Theme,
  createStyles,
  Typography,
  Button,
  Popover,
} from '@material-ui/core';
import { HexColorPicker } from 'react-colorful';

class ColorPicker extends Component<Props, cState> {
  static defaultProps = {
    color: 'AiSensy',
  };
  state = {
    countryCode: '',
    name: this.props.color,
    copied: false,
    anchorEl: null
  };
  handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  }
  handleClose = () => {
    this.setState({ anchorEl: null });
  }
  // async componentDidMount(): Promise<void> {}
  render(): React.ReactElement {
    const { classes } = this.props;
    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
      <div className={classes.root} >
        <div 
          className={classes.root} 
          onClick={this.handleClick}
          style={{background: this.props.color}}        
        >
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className={classes.demopicker}>
            <HexColorPicker color={this.props.color} onChange={this.props.setColor} />
          </div>
        </Popover>
      </div>
    );
  }
}

interface Props extends WithStyles<typeof styles> {
  color: string;
  setColor: (value: string) => void;
  // number: string
}

interface cState {
  countryCode: string;
  name: string;
  copied: boolean;
  anchorEl: HTMLDivElement | null
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: 25,
      height: 25,
      borderRadius: "50%",
      cursor: "pointer"
    },
    demopicker: {
      width: 200,
      height: 200,
      background: "transparent",
      overflow: "hidden"
    }
  });

export default withStyles(styles)(ColorPicker);
